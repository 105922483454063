<!--  -->
<template>
  <div>
    <HeaderTop />
    <div id="Investment">
      <div class="main wrapper">
        <div>
          <div id="XYRB">
            <!-- 头部 -->
            <div id="BetHeader">
              <div id="MainHead">
                <div class="left-head">
                  <img
                    width="97"
                    height="97"
                    class="icon"
                    data-src="https://static.flsystem199.com/alpe/8ef04ebe3025d034bf81d0eac90061de.png"
                    :src="configUrl + productTop.icon"
                    lazy="loaded"
                  />
                  <div class="info">
                    <div class="info-title">
                      <span>{{ productTop.show_name }}</span>
                    </div>
                    <div class="result XYRB">
                      <div class="number-xyrb">
                        <span class="current-count">
                          轮数
                          <em>{{ topLast.rounds }}</em></span
                        >
                        <div class="number">
                          <span>{{ topLast.onenum }}</span>
                          <i>+</i>
                        </div>
                        <div class="number">
                          <span>{{ topLast.twonum }}</span>
                          <i>+</i>
                        </div>
                        <div class="number">
                          <span>{{ topLast.threenum }}</span>
                          <i>=</i>
                        </div>
                        <div class="count">{{ topLast.sumnum }}</div>
                        <span> {{ topLast.content }} </span>
                      </div>
                    </div>
                  </div>
                  <div class="saleInfo">
                    <p>距 {{ lunNum }} 轮投资截止</p>
                    <div class="times">
                      <span>{{ topMinutes }}</span>
                      <span>:</span>
                      <span>{{ topseconds }}</span>
                    </div>
                  </div>
                  <div class="links bet">
                    <a
                      ><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAQCAYAAADwMZRfAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6M0M2RDhFMTkzNkQ5MTFFQjgwOUQ5OEI1NTE1OTM4QkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0M2RDhFMUEzNkQ5MTFFQjgwOUQ5OEI1NTE1OTM4QkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozQzZEOEUxNzM2RDkxMUVCODA5RDk4QjU1MTU5MzhCRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDozQzZEOEUxODM2RDkxMUVCODA5RDk4QjU1MTU5MzhCRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pg9/LNMAAAEhSURBVHjapNMxS8NAGMbxa6JiBxVxkDrpJOjsp1E66qC49gP0g7j5XbRjq+ggOkkXEStaq03P/5Xn4LxcKNIXfqR5c/fkmlxMMR6ZhBpO8QCr45n6f8Zaa42pCGlpcqyVCpkmR1XDC9ZNuV6xAesbWb5kssTAekWAUb8eN1Mhn+hWhHR1fWaIq3MMo95Q/VKlnomvHdxiGV/Yw2NpFRXPxJeb4O8wSgXM+jv/qoXg9wry4Pwt8erXgvMC7+FKLjDQPvBOopCj6PpA86YhDTQxRhvHuNae+FCAO27iStfbWklzUnw3wi3eR67t7L6bHg5xqWNPfaNxff8puFd8R+Ku7viEZxxgUUu+wT5W8YMOtrCtOfcuxM77djIlz1OdXwEGADUk5tr4OXX+AAAAAElFTkSuQmCC"
                        alt=""
                      />
                      <span>产品介绍</span></a
                    >
                    <a
                      ><img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAQCAYAAAD52jQlAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjZDMURGQUMzNkQ4MTFFQjgwOUQ5OEI1NTE1OTM4QkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6M0M2RDhFMTYzNkQ5MTFFQjgwOUQ5OEI1NTE1OTM4QkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGNkMxREZBQTM2RDgxMUVCODA5RDk4QjU1MTU5MzhCRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGNkMxREZBQjM2RDgxMUVCODA5RDk4QjU1MTU5MzhCRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PjkrkOcAAAGZSURBVHjalJTNK0RRFMDnA6uZjYjGdzJEIYmFksWUZKdsLJRSLLHzB1jxD5iNlSwsFGJDlqSIYqEpX4kmKyYx03z4HZ2n63lvpjn167177r3n3vN1vZl00pNHemEWhqFWdY+wC1G49PnL/m3yuhitgw0Y0PEVnIFfD2pT/QGMYTjxZ7cYtdEHOWXGYd5iXtekoD2Xy3ks7DcNww08Q43qFmBC59J661VYB/H9E3wQ4sYvTu6/QoXooRGOodol3hfQA0F4gxhG5eCfEywZV4Ndqr/LY1CkWzzC0LsmsiWbSQ2aMW2FOFzreNuIayHmJI58nyAGDTKYNBZMQ2kRBoWEGl22dOLmqOFSUkumGAngdhXf37ISo1+2ReWe4iVoDkogbiv6U4dND7BlJLQSzmEf9uAWhsyOCmgtSqY34QiyWlaWNOtG83DxsAk6qIA1QjDFfz/cu3VL1JaMFQgri3ACHzq3ZHaTU0eZMZLOqS8Qy0NuGbErfS6LJZOdGi83kVcq4jThLfD0iYxoEkIaa3n6dqyEOj193wIMABmPeGSvBGagAAAAAElFTkSuQmCC"
                        alt=""
                      />
                      <span>在线客服</span></a
                    >
                    <a
                      ><el-switch
                        active-color="#13ce66"
                        inactive-color="rgb(26, 25, 31)"
                        v-model="otherInv"
                        active-text="他人投资"
                      ></el-switch
                    ></a>
                  </div>
                </div>
                <div
                  class="el-dialog__wrapper lottery-rule-modal"
                  style="display: none"
                >
                  <div
                    role="dialog"
                    aria-modal="true"
                    aria-label="dialog"
                    class="el-dialog"
                    style="margin-top: 15vh; width: 595px"
                  >
                    <div class="el-dialog__header">
                      <span class="el-dialog__title"></span
                      ><button
                        type="button"
                        aria-label="Close"
                        class="el-dialog__headerbtn"
                      >
                        <i class="el-dialog__close el-icon el-icon-close"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 表格 -->
            <div id="Chat" v-if="otherInv">
              <div class="table">
                <nav class="title">
                  <p>用户</p>
                  <p>轮数</p>
                  <p>交易品类</p>
                  <p>交易内容</p>
                  <p>交易金额</p>
                  <p>操作</p>
                </nav>
                <ul class="content">
                  <li
                    v-for="(item, index) in otherInvesList.slice(0, 5)"
                    :key="index"
                  >
                    <p>{{ item.username }}</p>
                    <p>{{ item.num }}</p>
                    <p>{{ item.show_name }}</p>
                    <p>{{ item.show_xize }}</p>
                    <p>{{ item.money }}</p>
                    <p>
                      <span class="btn-chess" @click="gdDialog(item)">
                        跟单
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
              <CheseDialog ref="cheseDialog" />
            </div>
            <!-- 操作部分 -->
            <div class="cont">
              <div class="left">
                <div id="NoteRecord">
                  <el-tabs type="border-card">
                    <el-tab-pane label="历史详情">
                      <table class="record-table" v-if="historyList.length > 0">
                        <thead>
                          <tr>
                            <th>轮数</th>
                            <th>结果</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in historyList" :key="index">
                            <td>
                              <span class="lottery-number">{{
                                item.rounds
                              }}</span>
                            </td>
                            <td>
                              <span class="code no-wrap"
                                >{{ item.onenum }}+{{ item.twonum }}+{{
                                  item.threenum
                                }}={{ item.sumnum }}</span
                              >
                              <span class="code result">
                                {{ item.content }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div v-else class="no-data">
                        <img src="../../../assets/img/no-data.png" alt="" />
                        <span>暂无数据</span>
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="投资记录">
                      <el-table
                        v-if="investmentRecord.length > 0"
                        :data="investmentRecord"
                        border
                        style="width: 100%"
                        :show-header="false"
                      >
                        <el-table-column align="center" prop="number">
                          <template slot-scope="scope">
                            <p class="numbers">
                              {{ scope.row.number }}
                            </p>
                          </template>
                        </el-table-column>
                        <el-table-column align="center" prop="number">
                          <template slot-scope="scope">
                            <p class="code">
                              {{ scope.row.content }}
                            </p>
                            <p class="money">
                              {{ scope.row.zongjine }}
                            </p>
                          </template>
                        </el-table-column>
                        <el-table-column align="center" prop="shouyi">
                          <template slot-scope="scope">
                            <p v-if="scope.row.status == 2">投资失败</p>
                            <p
                              v-else-if="scope.row.status == 1"
                              class="success"
                            >
                              +{{ scope.row.shouyi }}
                            </p>
                            <p v-else class="success">待结算</p>
                          </template>
                        </el-table-column>
                      </el-table>
                      <div class="no-data" v-else>
                        <img src="../../../assets/img/no-data.png" alt="" />
                        <span>暂无数据</span>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
              <div class="right">
                <div id="CheckArea">
                  <el-tabs @tab-click="tabChange" type="border-card">
                    <el-tab-pane label="常规">
                      <ul class="list play_conventional">
                        <li
                          class="item"
                          @click="conventionalChange(item, index)"
                          v-for="(item, index) in conventional"
                          :key="index"
                        >
                          <div
                            :class="{
                              active: arrIncludes(item.id),
                            }"
                          >
                            <p>{{ item.show_xize }}</p>
                            <p>{{ item.peilv }}</p>
                          </div>
                        </li>
                      </ul>
                      <!-- 购入按钮 -->
                      <div class="action">
                        <el-input
                          class="money"
                          v-model="money"
                          placeholder="请输入内容"
                        ></el-input>
                        <el-button class="btn-buy" @click="placeOrderFun"
                          >购入</el-button
                        >
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="特殊">
                      <ul class="list play_special">
                        <li
                          class="item"
                          @click="conventionalChange(item, index)"
                          v-for="(item, index) in special"
                          :key="index"
                        >
                          <div
                            :class="{
                              active: arrIncludes(item.id),
                            }"
                          >
                            <p>{{ item.show_xize }}</p>
                            <p>{{ item.peilv }}</p>
                          </div>
                        </li>
                      </ul>
                      <!-- 购入按钮 -->
                      <div class="action">
                        <el-input
                          class="money"
                          v-model="money"
                          placeholder="请输入内容"
                        ></el-input>
                        <el-button class="btn-buy" @click="placeOrderFun"
                          >购入</el-button
                        >
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="数字">
                      <ul class="list play_digital">
                        <li
                          class="item"
                          @click="conventionalChange(item, index)"
                          v-for="(item, index) in digital"
                          :key="index"
                        >
                          <div
                            :class="{
                              active: arrIncludes(item.id),
                            }"
                          >
                            <p>{{ item.show_xize }}</p>
                            <p>{{ item.peilv }}</p>
                          </div>
                        </li>
                      </ul>
                      <!-- 购入按钮 -->
                      <div class="action">
                        <el-input
                          class="money"
                          v-model="money"
                          placeholder="请输入内容"
                        ></el-input>
                        <el-button class="btn-buy" @click="placeOrderFun"
                          >购入</el-button
                        >
                      </div>
                    </el-tab-pane>
                    <el-tab-pane label="高低">
                      <ul class="list play_high_low">
                        <li
                          class="item"
                          @click="conventionalChange(item, index)"
                          v-for="(item, index) in highLow"
                          :key="index"
                        >
                          <div
                            :class="{
                              active: arrIncludes(item.id),
                            }"
                          >
                            <p>{{ item.show_xize }}</p>
                            <p>{{ item.peilv }}</p>
                          </div>
                        </li>
                      </ul>
                      <!-- 购入按钮 -->
                      <div class="action">
                        <el-input
                          class="money"
                          v-model="money"
                          placeholder="请输入内容"
                        ></el-input>
                        <el-button class="btn-buy" @click="placeOrderFun"
                          >购入</el-button
                        >
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                  <ConfirmDialog
                    @close="orderDialogClose"
                    v-if="orderDialogShow"
                    :checkedItemList="checkedItemList"
                    :jyMoney="money"
                    :jyPl="jyPl"
                    @orderConfirm="orderConfirm"
                    ref="orderDialog"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  othersInvestment,
  historyMarket,
  palyInfo,
  inRecord,
  placeOrder,
  topData,
} from "@/api/investhome";
import HeaderTop from "@/components/HeaderTop/HeaderTop.vue";
import CheseDialog from "@/components/CheseDialog/CheseDialog";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "",
  components: { CheseDialog, HeaderTop, ConfirmDialog },
  data() {
    return {
      topMinutes: "00",
      topseconds: "00",
      timerLun: null,
      lastTime: "",
      configUrl: process.env.VUE_APP_BASE_API,
      productTop: {
        icon: "",
      },
      topLast: {},
      lunNum: 0, // 轮数
      otherInv: true,
      money: "",
      jyPl: "常规",
      timer: null,
      productId: -1,
      deliteId: -1,
      otherInvesList: [], // 他人投资
      historyList: [], // 历史行情
      investmentRecord: [], // 投资记录
      playType: -1, // 玩法等级
      conventional: [], //常规玩法
      special: [], //特殊玩法
      digital: [], //数字玩法
      highLow: [], //高低玩法
      checkedList: [], // 选中的id
      checkedItemList: [], //选中的项目
      orderDialogShow: false,
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted(e) {
    let option = this.$route.query;
    if (option.product && option.detile) {
      // 接收参数
      this.productId = option.product;
      this.deliteId = option.detile;
      this.playType = option.type;
      /* 他人投资 */
      this.othersInvestmentShow();
      this.historyMarketShow();
      this.inRecordShow();
      this.palyInfoShow();
      this.topDataShow();
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    clearInterval(this.timerLun);
    this.timer = null;
    this.timerLun = null;
  },
  methods: {
    tabChange(e) {
      console.log(e);
      console.log("切换了");
      this.jyPl = e.label;
      this.resetForm();
    },
    /* 重置数据 */
    resetForm() {
      this.money = "";
      this.checkedList = [];
      this.checkedItemList = [];
    },
    /* 数组处理 */
    arrSplice() {
      let result = this.otherInvesList;
      this.otherInvesList.splice(0, 1); // 删除数组第一条数据
      this.otherInvesList.splice(result.length - 1, 0, result[0]); // 在末尾追加第一条数据
    },
    /* 验证是否包含 */
    arrIncludes(id) {
      return this.checkedList.includes(id);
    },
    /* 常规选中 */
    conventionalChange(item, index) {
      /* item.checked = !item.checked
      this.conventionalActive = index; */
      let sgin = this.checkedList.indexOf(item.id);
      let sgin2 = this.checkedItemList.indexOf(item);
      if (sgin2 + "" !== "-1") {
        this.checkedItemList.splice(sgin2, 1);
      } else {
        this.checkedItemList.push(item);
      }
      if (sgin + "" !== "-1") {
        // 如果包含
        this.$nextTick(() => {
          this.checkedList.splice(sgin, 1);
        });
        return;
      } else {
        this.checkedList.push(item.id);
      }
    },
    jishiqi() {
      var dj = this.lastTime;
      if (dj <= 0) {
        console.log("过期了========================================");
        this.lastTime = "已过期";
        this.topDataShow();
      } else {
        var ddf = this.djs(dj); //格式化过后的时间
        this.lastTime = ddf;
        //当前时间减去时间
        dj = dj - 1;
        this.lastTime = dj;
      }
    }, //得到的秒换算成时分秒
    djs(number) {
      var hour = Math.floor(number / 3600), //小时
        minutes = Math.floor((number - hour * 3600) / 60),
        seconds = Math.floor(number - (hour * 3600 + minutes * 60));
      if (minutes < 10) {
        minutes = 0 + "" + minutes;
      }
      if (seconds < 10) {
        seconds = 0 + "" + seconds;
      }
      this.topMinutes = minutes;
      this.topseconds = seconds;
      return minutes + ":" + seconds;
    },
    /* 查询顶部数据 */
    topDataShow() {
      topData({
        product_id: this.productId,
      }).then((res) => {
        console.log(res);
        let result = res.data;
        this.productTop = result.product;
        this.lunNum = result.num;
        this.topLast = result.last;
        console.log(result.end_time);
        let endTime = new Date(result.end_time).getTime() / 1000;
        let startTime = new Date().getTime() / 1000;

        this.lastTime = endTime - startTime;
        console.log(this.lastTime);
        clearInterval(this.timerLun);
        this.timerLun = setInterval(() => {
          this.jishiqi();
        }, 1000);
      });
    },
    /* 玩法查询 */
    palyInfoShow() {
      palyInfo({
        product_id: this.productId,
        type: this.playType,
        lang: "english",
      }).then((res) => {
        console.log(res);
        let result = res.data;

        this.conventional = result.changgui; //常规玩法
        this.special = result.teshu; //特殊玩法
        this.digital = result.shuzi; //数字玩法
        this.highLow = result.gaodi; //高低玩法
      });
    },
    /* 投资记录 */
    inRecordShow() {
      inRecord({
        product_id: this.productId,
      }).then((res) => {
        this.investmentRecord = res.data;
      });
    },
    /* 历史行情 */
    historyMarketShow() {
      historyMarket({
        product_id: this.productId,
        lang: "english",
      }).then((res) => {
        this.historyList = res.data;
      });
    },
    /* 他人投资 */
    othersInvestmentShow() {
      othersInvestment({
        product_id: this.productId,
        lang: "english",
      }).then((res) => {
        console.log(res);
        this.otherInvesList = res.data;
        if (this.timer) clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.arrSplice();
        }, 1000);
      });
    },
    /* 跟单操作 */
    gdDialog(item) {
      this.$refs.cheseDialog.open();
    },
    /* 下单确认弹窗 */
    placeOrderFun() {
      console.log(this.checkedList.length);
      if (this.checkedList.length <= 0) {
        this.$message({
          message: "请选择投资内容",
          type: "warning",
        });
        return false;
      }
      if (this.money === "") {
        this.$message({
          message: "请填写金额",
          type: "warning",
        });
        return false;
      }
      this.orderDialogShow = true;
      return;
    },
    /* 弹窗关闭 */
    orderDialogClose() {
      this.orderDialogShow = false;
    },
    /* 确认下单 */
    orderConfirm() {
      let len = this.checkedList.length;
      placeOrder({
        product_id: this.productId,
        hall_id: this.deliteId, // 厅id
        play_id: this.checkedList.join(","),
        money: this.money * len,
      }).then((res) => {
        console.log(res);
        this.$message({
          message: "买入成功",
          type: "success",
        });
        this.orderDialogClose();
        this.resetForm();
      });
    },
  },
};
</script>
<style scoped lang="less">
#app {
  min-width: 1272px;
}
/* @import url(); 引入css类 */
#Investment {
  background-image: url(../../../assets/img/bg.png);
  background-size: 100%;
  width: 100%;
  min-height: 100%;
  padding-bottom: 30px;
  padding-top: 20px;

  .main {
    margin: auto;
    margin-top: 0px;
    width: 1085px;
    min-width: 1085px;
    overflow: hidden;
    display: flex;

    #XYRB {
      background: #7d7b88;
    }
  }
}

#MainHead {
  background: #1a191f;
  display: flex;
  padding: 10px 0 10px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #aaa;
  justify-content: space-between;
  font-size: 16px;

  .left-head {
    display: flex;
    flex: 1;
  }

  .icon {
    margin-top: 10px;
  }

  .info {
    margin-left: 14px;

    .info-title:first-child {
      margin-top: 22px;
      height: 40px;
      line-height: 40px;
      font-size: 22px;
      color: #fff;
    }
  }

  .result {
    position: relative;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 14px;

    .number-xyrb {
      height: 30px;
      line-height: 30px;
      display: flex;

      .count {
        margin-right: 30px;
        line-height: 30px;
        display: inline-block;
        text-align: center;
        overflow: hidden;
      }

      .number span {
        display: inline-block;
        text-align: center;
        border-radius: 50%;
      }

      .number i {
        width: 46px;
        height: 30px;
        line-height: 30px;
      }
    }

    .current-count {
      margin-right: 30px;
      em {
        color: #fff600;
        font-weight: 400;
      }
    }
  }
  .saleInfo p:first-child {
    font-size: 16px;
    color: #fff;
    margin: 0 auto 10px;
  }
  .saleInfo {
    margin: 20px 80px 0;
    text-align: center;

    .times span:nth-child(2n) {
      margin: 0 12px;
    }

    .times {
      width: 160px;
      height: 40px;
      line-height: 40px;
      margin: 0 auto;
      font-family: PingFang-SC-Bold;
      background: #000;
      color: #ffff1b;
      font-size: 30px;
      border-radius: 4px;
      display: flex;
      justify-content: center;
    }
  }

  .links {
    a {
      display: flex;
      margin: 12px 0;
      line-height: 24px;
      color: #fff;
      font-size: 12px;
      letter-spacing: 1px;
      cursor: pointer;
      align-items: center;
      margin-left: 20px;

      span {
        margin-left: 5px;
      }
    }
    .bet {
      padding-right: 27px;
    }
  }
}

#Chat {
  .table {
    text-align: center;

    p {
      height: 38px;
      line-height: 38px;
      width: 16.66666667%;
    }

    .title {
      display: flex;
      align-items: center;
      background: #101010;
      color: #aaa;
    }

    .content {
      border-bottom: 1px solid #313131;

      .btn-chess {
        cursor: pointer;
        padding: 5px 15px;
        border-radius: 15px;
        width: 55px;
        height: 20px;
        color: #f5448b;
        border: 1px solid #f5448b;
      }

      li {
        display: flex;
        align-items: center;
        color: #fff;
        background-color: #222127;

        p {
          height: 42px;
          line-height: 42px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .content li:nth-child(2n-1) {
      background-color: #1a191f;
    }
  }
}

#XYRB .cont {
  display: flex;
}

#NoteRecord {
  background-color: #1a191f;
  padding: 20px;
  width: 385px;
  min-height: 330px;
  overflow: hidden;
  position: relative;
}

.record-table {
  text-align: center;
  width: 100%;
  color: #fff;
  border-collapse: collapse;
}

.record-table tr:nth-child(2n-1) {
  background: #262626;
}

.record-table th {
  width: 50%;
  height: 35px;
  line-height: 35px;
  color: #aaa;
  background: #201f22;
  font-weight: 400;
}

.record-table tr {
  height: 35px;
  line-height: 35px;
  background: #201f22;
}

.record-table td {
  line-height: 22px;
  vertical-align: middle;
}

.record-table td .no-wrap {
  display: block;
  width: 100%;
  white-space: nowrap;
}

#NoteRecord .no-data {
  width: 100%;
  height: 100%;
}
#NoteRecord .no-data img {
  display: block;
  margin: auto;
  margin-top: 10px;
}
#NoteRecord .no-data span {
  margin-top: 20px;
  font-size: 16px;
  color: #666;
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
}

#XYRB .right {
  background-color: #1a191f;
}

#CheckArea {
  background-color: #1a191f;
  padding: 20px 20px 20px 0;
}

/* 选项卡内的内容 */
#CheckArea .el-tabs .el-tabs__content .list.play_conventional .item {
  width: 25%;
}

#CheckArea .el-tabs .el-tabs__content .list .item {
  cursor: pointer;
  text-align: center;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding-right: 15px;
  margin-bottom: 15px;
}

#CheckArea .el-tabs .el-tabs__content .list .item > div {
  padding: 6px 0;
  height: 55px;
  border: 1px solid #fff;
  border-radius: 10px;
  line-height: 20px;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

#CheckArea .el-tabs .el-tabs__content .list.play_conventional .item:last-child,
#CheckArea
  .el-tabs
  .el-tabs__content
  .list.play_conventional
  .item:nth-last-child(2) {
  width: 50%;
}

#CheckArea .el-tabs .el-tabs__content .action {
  position: absolute;
  bottom: 15px;
}

#CheckArea .el-tabs .el-tabs__content .list .item > div.active {
  border-color: #ffff1b;
  color: #ffff1b;
}

#NoteRecord .el-table.el-table--border,
#NoteRecord .el-table .el-table--group {
  border: 0;
}

/deep/#NoteRecord .el-table td {
  border-color: #393939;
  border-right: 0;
  padding: 0;
  line-height: 30px;
  font-size: 12px;
}

/deep/#NoteRecord .el-table tr .cell {
  color: #fff;
}
/deep/#NoteRecord .el-table .cell .numbers {
  color: #aaa;
}

#NoteRecord .el-table .code {
  height: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#NoteRecord .el-table .cell .money {
  color: #ffff1b;
}

#NoteRecord .el-table .cell .success {
  color: #f5448b;
}

/deep/#NoteRecord .el-table tr {
  cursor: pointer;
  background-color: #222127;
}

.el-table__body-wrapper {
  overflow: hidden;
  position: relative;
}

/deep/#CheckArea .el-input__inner {
  background-color: transparent;
  background-image: none;
}

/deep/#CheckArea .el-tabs .el-tabs__content .money {
  width: 420px;
  margin-right: 15px;
}

/deep/#CheckArea .el-tabs .el-tabs__content .money input {
  color: #fff;
  border-color: #f7e59b;
}

/deep/#CheckArea .el-tabs .el-tabs__content .btn-buy {
  width: 158px;
  top: 1px;
  position: relative;
  font-size: 16px;
  color: #000;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAAAoCAYAAABD/E7rAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Njg0MURFRjMzNkRCMTFFQjgwOUQ5OEI1NTE1OTM4QkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Njg0MURFRjQzNkRCMTFFQjgwOUQ5OEI1NTE1OTM4QkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODQxREVGMTM2REIxMUVCODA5RDk4QjU1MTU5MzhCRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODQxREVGMjM2REIxMUVCODA5RDk4QjU1MTU5MzhCRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsSYn08AAAHPSURBVHja7N27SgNBFMbx4+YJLKIvYOOViIqNYK8PYBTBIo8lFoKQ+ADaCzaiYvDa2FqohaWVGc/ZzSSzkwsEbMz+f3hYNjM7wsl8xKzFTny/HYpxztmhJE6qeranp2t6LOu5uGxC5xjMTwdd+Fr647L5zk+I53TH0mN63h3PvRat171++Fh+jWBcesc7a+XmD15j0Jye3zXC+qNdG10nw8bD/sbr9+t9+H6Fc/wecP3f82h+uEf8nM54bv/E+yrYM8Hrf+hD61qrblU7brb8QBJMmtNqap1obWmVBRg/U1rb7X1+d3RQmY3DMK91qbVAr1AgS1oXGogZH4ZS+yNjkt6ggOwvoIYGIrEw6HcEWaQnKLAVrR0Lwy69AKRqYVilD4CsJ8JdI8BMJ5K/vQoUFkEACANAGADCABAGgDAAhAEgDABhAAgDQBgAwgAQBoAwAIQBIAwAYQAIA0AYAMIAEAaAMACEAfgXYWjRBiALwydtAOTdwnBDHwC5sjDU6QMgDQtDQ+uBXqDAbrVOLQw/kj2j4YueoIDsO3PVHnTob60+aW1oPdIbFMi91qYG4dVOwv8zPGtVtPa1zoW7TBhP9ujbs/Y+X9YgvPiBXwEGAAtrgFyfYnprAAAAAElFTkSuQmCC)
    no-repeat 50%;
  border: none;
}

/* 重写element样式 */
/deep/#NoteRecord .el-tabs--border-card {
  border: 0;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
}

/deep/#NoteRecord .el-tabs--border-card .el-tabs__header {
  border-radius: 10px 10px 0 0;
  background-color: #262626;
  border-bottom: 0;
}

/deep/#NoteRecord .el-tabs--border-card .el-tabs__header .el-tabs__nav {
  margin: 10px auto;
  height: 30px;
  width: 300px;
  border-radius: 10px;
  float: unset;
  display: flex;
  justify-content: center;
  background: #000;
  color: #aaa;
}

/deep/#NoteRecord
  .el-tabs--border-card
  .el-tabs__header
  .el-tabs__nav
  .el-tabs__item.is-active {
  background-color: transparent;
  color: #ffff1b;
}

/deep/#NoteRecord
  .el-tabs--border-card
  .el-tabs__header
  .el-tabs__nav
  .el-tabs__item {
  width: 50%;
  line-height: 30px;
  height: 30px;
  display: inline-block;
  padding: 0;
  border: 0;
  text-align: center;
  font-size: 12px;
  position: relative;
  z-index: 99;
}
/deep/#NoteRecord
  .el-tabs--border-card
  .el-tabs__header
  .el-tabs__nav
  .el-tabs__item:hover {
  color: #ffff1b;
}

/deep/#NoteRecord
  .el-tabs--border-card
  .el-tabs__header
  .el-tabs__nav
  .el-tabs__item:first-child:after {
  content: " ";
  border-left: 1px solid #aaa;
  position: absolute;
  top: 9px;
  right: 0;
  height: 15px;
}

#CheckArea .el-tabs .el-tabs__content .list.play_special .item {
  width: 33.33333333%;
}

#CheckArea .el-tabs .el-tabs__content .list.play_digital .item {
  width: 11.11111111%;
}

#CheckArea .el-tabs .el-tabs__content .list.play_high_low .item {
  width: 50%;
}

/deep/#NoteRecord .el-tab-pane {
  height: 100%;
}

/deep/#NoteRecord .el-tabs--border-card .el-tabs__content {
  padding: 0;
  min-height: 352px;
  background-color: #262626;
  border-radius: 0 0 10px 10px;
}

/deep/#CheckArea .el-tabs.el-tabs--border-card {
  background-color: #262626;
  border: none;
  color: #fff;
  border-radius: 10px;
}

/deep/#CheckArea .el-tabs .el-tabs__header {
  background-color: #101010;
  border-radius: 10px 10px 0 0;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

/deep/#CheckArea .el-tabs .el-tabs__header .el-tabs__item.is-active {
  color: #ffff1b;
  background: transparent;
  border-bottom: 3px solid #ffff1b;
}
/deep/#CheckArea .el-tabs .el-tabs__header .el-tabs__item {
  width: 125px;
  text-align: center;
  color: #fff;
  border: none;
  -webkit-transition: none;
  transition: none;
}

/deep/#CheckArea .el-tabs .el-tabs__content {
  width: 611px;
  min-height: 332px;
  padding-right: 0;
}

#NoteRecord .el-table.el-table--border:after,
#NoteRecord .el-table.el-table--border:before,
#NoteRecord .el-table .el-table--group:after,
#NoteRecord .el-table:before {
  background-color: #262626;
}

/deep/#NoteRecord .el-table tr:hover,
/deep/#NoteRecord .el-table tr:hover td {
  background-color: #201f22;
}
</style>