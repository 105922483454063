<!-- 跟单弹窗 -->
<template>
  <div id="CheseDialog" :style="{ display: isClose ? 'none' : 'flex' }">
    <div class="content" :class="[isShow ? 'show' : 'hide']">
      <div class="cont">
        <div class="close" @click="close">
          <i class="iconfont icon-guanbi"></i>
        </div>
        <div class="title">是否跟单？</div>
        <div class="content">
          <div class="row half">
            <div class="field">用户</div>
            <div class="value">***9898</div>
          </div>
          <div class="row half">
            <div class="field">轮数</div>
            <div class="value"><span> 110197 </span></div>
          </div>
          <div class="row">
            <div class="field">交易金额</div>
            <div class="value">0 {{$t('common.CNY')}}</div>
          </div>
          <div class="row">
            <div class="field">交易品类</div>
            <div class="value">常规</div>
          </div>
          <div class="row">
            <div class="field">交易内容</div>
            <div class="value">多单</div>
          </div>
          <div class="action">
            <el-button @click="close">取消</el-button>
            <el-button class="btn-buy">确认</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CheseDialog",
  data() {
    return {
      isShow: false,
      isClose: true
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {},
  methods: {
    open() {
      this.isShow = true;
      this.isClose = false;
    },
    close() {
      this.isShow = false;
      setTimeout(()=> {
        this.isClose = true;
      }, 400)
    }
  },
};
</script>
<style scoped lang="less">
/* @import url(); 引入css类 */
#CheseDialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  background: rgba(0, 0, 0, 0.5);
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr=#80000000, endColorstr=#80000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1,startColorstr=#80000000,endColorstr=#80000000);
  z-index: 101;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#CheseDialog .content {
  padding: 20px;
  font-size: 14px;
  color: #fff;
}

#CheseDialog .content .cont {
  width: 520px;
  min-height: 290px;
  background: #222127;
  border-radius: 10px;
  padding-top: 60px;
  position: relative;
  overflow: hidden;
}

#CheseDialog .content .close {
  width: 53px;
  text-align: center;
  display: block;
  position: absolute;
  top: 11px;
  right: 0;
  z-index: 10;
  cursor: pointer;
}

#CheseDialog .content .close .iconfont {
  margin: auto;
  display: block;
  font-size: 25px;
  color: #fff;
  padding: 4px 9px;
}

#CheseDialog .content .title {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  background: url(../../assets/img/title.png) 50% no-repeat;
  line-height: 60px;
  font-size: 16px;
}

#CheseDialog .content {
  padding: 20px;
  font-size: 14px;
  color: #fff;
}

#CheseDialog .content .row {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

#CheseDialog .content .row.half {
  width: 50%;
}

#CheseDialog .content .row .field {
  width: 150px;
  float: left;
}

#CheseDialog .action {
  position: absolute;
  bottom: 20px;
}

#CheseDialog .action button,
#CheseDialog .action button:hover {
  border-radius: 10px;
  border-color: #fff;
  background: transparent;
}

#CheseDialog .action .btn-buy,
#CheseDialog .action .btn-buy:hover {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMMAAAAoCAYAAABD/E7rAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQwIDc5LjE2MDQ1MSwgMjAxNy8wNS8wNi0wMTowODoyMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTggKE1hY2ludG9zaCkiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Njg0MURFRjMzNkRCMTFFQjgwOUQ5OEI1NTE1OTM4QkUiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Njg0MURFRjQzNkRCMTFFQjgwOUQ5OEI1NTE1OTM4QkUiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo2ODQxREVGMTM2REIxMUVCODA5RDk4QjU1MTU5MzhCRSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo2ODQxREVGMjM2REIxMUVCODA5RDk4QjU1MTU5MzhCRSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PsSYn08AAAHPSURBVHja7N27SgNBFMbx4+YJLKIvYOOViIqNYK8PYBTBIo8lFoKQ+ADaCzaiYvDa2FqohaWVGc/ZzSSzkwsEbMz+f3hYNjM7wsl8xKzFTny/HYpxztmhJE6qeranp2t6LOu5uGxC5xjMTwdd+Fr647L5zk+I53TH0mN63h3PvRat171++Fh+jWBcesc7a+XmD15j0Jye3zXC+qNdG10nw8bD/sbr9+t9+H6Fc/wecP3f82h+uEf8nM54bv/E+yrYM8Hrf+hD61qrblU7brb8QBJMmtNqap1obWmVBRg/U1rb7X1+d3RQmY3DMK91qbVAr1AgS1oXGogZH4ZS+yNjkt6ggOwvoIYGIrEw6HcEWaQnKLAVrR0Lwy69AKRqYVilD4CsJ8JdI8BMJ5K/vQoUFkEACANAGADCABAGgDAAhAEgDABhAAgDQBgAwgAQBoAwAIQBIAwAYQAIA0AYAMIAEAaAMACEAfgXYWjRBiALwydtAOTdwnBDHwC5sjDU6QMgDQtDQ+uBXqDAbrVOLQw/kj2j4YueoIDsO3PVHnTob60+aW1oPdIbFMi91qYG4dVOwv8zPGtVtPa1zoW7TBhP9ujbs/Y+X9YgvPiBXwEGAAtrgFyfYnprAAAAAElFTkSuQmCC)
    no-repeat 50%;
  background-size: cover;
  border: none;
}
</style>